body {
  background-color: white;
  color: black;
  margin: 0;
  hyphens: auto;
  word-wrap: break-word;
  word-break: break-word;
  -ms-word-break: break-all;
  overflow-wrap: break-word;
}

i {
  font-style: normal;
}

.Spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
